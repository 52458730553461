<template>
  <div class="run-order">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
      <div class="searchForm">
        <el-form ref="form" :model="form" :inline="true" label-position="left" class="form">
          <el-form-item label-width="70px"  label="配送单号">
            <el-input type="number" class="inputStyle" clearable v-model="form.skOrderId" 
            placeholder="" style="display:inline-block"></el-input>
          </el-form-item>
          <el-form-item  label-width="70px" label="订单号">
            <el-input type="number" class="inputStyle" clearable v-model="form.deliveryId" placeholder=""></el-input>
          </el-form-item>
          <el-form-item  label-width="80px" label="订单类型">
            <el-select v-model="form.orderSubType" clearable placeholder="">
              <el-option v-for="item in orderTypeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态" label-width="80px">
            <el-select v-model="form.state" clearable placeholder="">
              <el-option v-for="item in orderStatusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="询价渠道" label-width="80px">
            <el-select v-model="form.agentSid" clearable placeholder=""  filterable
           remote
           :remote-method="agentMethod">
              <el-option v-for="item in enquiryChannelList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="timeAndBtn flexbox flex-lr flex-align-center">
          <div class="timeChoose" style="padding-right:10px">时间筛选</div>
          <el-date-picker v-model="time" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" :picker-options="pickerOptions">
          </el-date-picker>
          <el-button type="primary" class="sure" @click="search">筛选</el-button>
          <el-button type="info" class="sure" @click="clear">清空</el-button>
          <PrevReclick>
            <!-- <div @click="exportExcle">导出数据</div> -->
            <el-button type="primary" class="sure" @click="excleVisible=true">导出数据</el-button>
          </PrevReclick>
        </div>
      </div>
    </div>
    <div class="orderDetail">
      <span>订单总金额：{{orderNum}}</span>
      <span>订单总笔数：{{orderAmount}}</span>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="orderSubType" label="询价渠道" width="100">
          <template slot-scope="scope">
            <span>{{scope.row.accessSubId === 1 || scope.row.accessSubId===10000?'平台':scope.row.accessSubName||'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderSubType" label="订单类型" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.orderType === 2">{{typeDateLists[scope.row.orderSubType] || '-'}}</span>
          </template>
        </el-table-column>
        
         <el-table-column prop="deliveryId" label="订单号"></el-table-column>
        <el-table-column prop="skOrderId" label="配送单号"></el-table-column>
       
        <el-table-column prop="createTime" label="订单创建时间" width="160"></el-table-column>
        <el-table-column label="订单结束时间" width="160">
          <template slot-scope="scope">
            <span>{{scope.row.realArriveTime || '—'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户支付商品价格" width="140">
          <template slot-scope="scope">
            <span>{{scope.row.goodsPrice || '—'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="distributeMoney" label="用户支付服务费" width="140"></el-table-column>
        <el-table-column label="骑手本单收入" width="140">
          <template slot-scope="scope">
            <span>{{scope.row.profitMoney || '—'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="我的分润" width="140">
          <template slot-scope="scope">
            <span>{{scope.row.agentProfit || '—'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="订单状态" width="140">
          <template slot-scope="scope">
            <span>{{orderStatusLists[scope.row.state||7].label || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="orderDetail(scope.row)">订单详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <!-- <el-dialog title="订单详情" :visible.sync="dialogVisible" width="500px" @close='dialogVisible=false' center class="order-detail">
      <header>
        <h3>用户信息</h3>
        <p>手机号：</p>
      </header>
      <h3>订单信息</h3>
      <p class="line-mark"></p>
      <ul>
        <li>订单号：</li>
        <li>配送单号：</li>
        <li>订单类型：</li>
        <li>物品信息：</li>
        <li>收货密码：</li>
        <li>无需保温箱配送</li>
        <li>购买时间：</li>
         <li>用户支付商品价格：</li>
      </ul>
      <h3>购买信息</h3>
      <p class="line-mark"></p>
      <ul>
        <li>寄件人姓名：</li>
        <li>寄件地址：</li>
      </ul>
       <h3>收货信息</h3>
      <p class="line-mark"></p>
      <ul>
        <li>收件人姓名：</li>
        <li>收件地址：</li>
      </ul>
       <h3>骑手信息</h3>
      <p class="line-mark"></p>
      <ul>
        <li>骑手姓名：</li>
       
      </ul>
    </el-dialog> -->
        <!-- 订单详情弹窗 -->
    <runOrderDetail ref="runOrderDetail"></runOrderDetail>
        <!-- 导出弹窗 -->
    <el-dialog title="导出数据" :visible.sync="excleVisible" width="400px" >
      <span style="padding-right:10px">选择日期：</span>
      <el-date-picker v-model="excleTime"  type="daterange" range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期" :picker-options="pickerOptions" style="width: 260px" >
      </el-date-picker>
      <p style="padding-top:20px;color:red">注：不能跨月导出数据</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="excleVisible = false">取 消</el-button>
         <PrevReclick>
        <el-button type="primary" @click="exportExcle">确认导出</el-button>
         </PrevReclick>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  $sg_getRunErrandsList, $sp_agentList, $sp_spreadList, $sp_shopInfoList, 
  $sg_getRunErrands, 
  $sg_getEnquiryChannel, 
} from '@/api/order';
import runOrderDetail from '@/components/order/runOrderDetail.vue';
import { exportFileSync } from '@base/utils';

export default {
  data() {
    return {
      typeDateLists: ['', '帮取', '帮送', '帮买', '', '', '帮排队', '全能帮手'],
      orderTypeList: [
        { value: '', label: '全部' },
        { value: 1, label: '帮取' },
        { value: 2, label: '帮送' },
        { value: 3, label: '帮买' },
        { value: 6, label: '帮排队' },
        { value: 7, label: '全能帮手' },
      ],
      // 1待接单 2骑手已接单 3已到服务/取货地址 4进行中   5已完成 6已取消
      orderStatusList: [
        { value: '', label: '全部' },
        { value: 1, label: '骑手待接单' },
        { value: 2, label: '已接单' },
        { value: 3, label: '已到服务/取货地址' },
        { value: 4, label: '进行中' },
        { value: 5, label: '已完成' },
        { value: 6, label: '已取消' },
      ],
      orderStatusLists: [
        { value: '', label: '全部' },
        { value: 1, label: '骑手待接单' },
        { value: 2, label: '已接单' },
        { value: 3, label: '已到服务/取货地址' },
        { value: 4, label: '进行中' },
        { value: 5, label: '已完成' },
        { value: 6, label: '已取消' },
        { value: 7, label: '-' },
      ],
      pickerOptions: {
        disabledDate(time) {
          // 获取2020年12月31日的时间戳
          const startTime = new Date('2020-12-31').getTime();
          // 获取目前时间的时间戳
          const endTime = new Date().getTime();
          // 禁止选择小于2021年1月1日之前和当前日期之后的日期
          return time.getTime() < startTime || time.getTime() > endTime;
        },
      },
      form: {
        groupOrderId: '',
        spreadId: '',
        agentId: '',
        state: '',
        payState: '',
        orderSubType: '',
      },
      orderDetailMsg: { },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      enquiryChannelList: [],
      branchOfficeList: [],
      loading: false,
      excleVisible: false,
      excleTime: null,
      agentList: [],
      shopList: [],
      payStatusList: [
        {
          value: 1,
          label: '待核销',
        },
        {
          value: 2,
          label: '已核销',
        },
        {
          value: 11,
          label: '已失效',
        },
        {
          value: 5,
          label: '已退款',
        },
      ],
      payMethodList: [
        {
          value: 1,
          label: '已支付',
        },
        {
          value: 2,
          label: '已退款',
        },
      ],
      orderAmount: 0,
      orderNum: 0,
      dialogVisible: true,
    };
  },
  components: {
    runOrderDetail,
  },
  created() {
    this.getTableData();
    this.getEnquiryChannel();
  },
  mounted() {
    this.getSpreadList();
    this.getAgentList();
    this.getShopList();
    // this.getGroupBuyAmount();
  },
  methods: {
    // 搜索
    agentMethod(key) {
      $sg_getEnquiryChannel({ key }).then((res) => {
        this.enquiryChannelList = res;
      });
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        groupOrderId: '',
        spreadId: '',
        agentId: '',
        shopId: '',
        state: '',
        payState: '',
      };
      this.time = '';
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 20,
        ...this.form,
      };
      if (this.time) { 
        params.startTime = this.time[0];
        params.endTime = this.time[1];
      } else {
        params.startTime = '';
        params.endTime = '';
      }
      // 请求订单数据
      $sg_getRunErrandsList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
      // 请求订单金额和笔数
      $sg_getRunErrands(params).then((res) => {
        this.orderAmount = res.orderCount;
        this.orderNum = res.orderPrice;
      });
    },
    // 导出数据
    exportExcle() {
      let startTime = null;
      let endTime = null;
      if (this.excleTime) {
        console.log(this.excleTime, '34566');
        startTime = this.excleTime[0];
        endTime = this.excleTime[1];
        if (startTime.getMonth() !== endTime.getMonth()) {
          this.$message.error('不能跨月导出数据');
          return;
        } else {
          startTime = (this.excleTime[0]).getTime() / 1000 || null; 
          endTime = (this.excleTime[1]).getTime() / 1000 || null;
        }
      } else {
        this.$message.error('请选择时间');
        return;
      }
      
      exportFileSync(
        '/j/distribution/agent/platfrom/order/runErrands/export',
        {
          startTime,
          endTime,
        },
        'post',
        '跑腿订单.xlsx',
        this.onDownloadProgress,
      ).then(() => {
        this.excleVisible = false;
      });
    },
    getEnquiryChannel() {
      // 请求询价渠道
      $sg_getEnquiryChannel().then((res) => {
        this.enquiryChannelList = res;
      });
    },
    // 订单详情
    orderDetail({ skOrderId, orderSubType }) {
      this.$refs.runOrderDetail.open(skOrderId, orderSubType);
    },
    // 搜索归属分公司
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.getSpreadList(query);
      } else {
        await this.getSpreadList('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    getSpreadList(platName = '') {
      const params = {
        platName,
      };
      this.branchOfficeList = [];
      return $sp_spreadList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    // 选择归属分公司
    spreadIdChange() {
      this.form.agentId = '';
      this.form.shopId = '';
      this.getAgentList();
      this.getShopList();
    },
    // 搜索代理商
    async searchAgent(query) {
      this.loading = true;
      if (query) {
        await this.getAgentList(query);
      } else {
        await this.getAgentList('');
      }
      this.loading = false;
    },
    // 请求代理商列表
    getAgentList(agentName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentName,
      };
      this.agentList = [];
      return $sp_agentList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.agentSid,
            label: item.agentName,
          };
          tempArr.push(json);
        });
        this.agentList = tempArr;
      });
    },
    // 选择代理商
    agentSidChange() {
      this.form.shopId = '';
      this.getShopList();
    },
    // 搜索商户
    async searchShop(query) {
      this.loading = true;
      if (query) {
        await this.getShopList(query);
      } else {
        await this.getShopList('');
      }
      this.loading = false;
    },
    // 请求商户列表
    getShopList(shopName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentSid: this.form.agentSid,
        shopName,
      };
      this.shopList = [];
      return $sp_shopInfoList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.shopId,
            label: item.shopName,
          };
          tempArr.push(json);
        });
        this.shopList = tempArr;
      });
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.run-order {
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .el-form--inline .el-form-item {
  margin-right: 60px;
  }
  .search-style {
    // width: 240px;
  }
  /deep/.el-input__inner{
    width:160px;
  }
  .timeAndBtn{
    /deep/.el-input__inner{
    width:260px!important;
  }
  }
  .sure {
  margin-left: 30px;
  }
  .order-detail {
    header {
      border: 1px solid #ebeef5;
      padding: 10px 20px 20px 20px;
      border-radius: 10px;
      p {
        margin-top: 30px;
      }
    }
    .line-mark {
      width: 100%;
      border-bottom: 1px solid #ebeef5;
      padding-bottom: 5px;
    }
    li{
        line-height: 25px;
    }
  }
  .orderDetail {
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child {
      margin-right: 80px;
    }
  }
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table {
    min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
}
</style>

